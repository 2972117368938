/* Font ve genel stiller */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: #f5f6fa;
}

/* Form Container Styles */
.project-details-container,
.personal-info-container,
.integrations-container,
.design-details-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: clamp(1.5rem, 5vw, 3rem);
  background: #fff;
  min-height: 100vh;
}

/* Section Title Styles */
.project-details-title,
.integrations-title,
.design-details-title {
  color: #3e478c;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}

/* Section Styles */
.project-details-section,
.personal-info-section,
.integrations-section,
.design-details-section {
  margin-bottom: clamp(1.5rem, 3vw, 2rem);
}

/* Label Styles */
.project-details-label,
.personal-info-label,
.integrations-label,
.design-details-label {
  display: block;
  color: #3e478c;
  font-size: clamp(0.875rem, 2vw, 1rem);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

/* Info Text Styles */
.project-details-info,
.design-details-info {
  display: block;
  color: #666;
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
  font-style: italic;
  margin-bottom: 0.5rem;
}

/* Input Styles */
.project-details-input,
.personal-info-input,
.integrations-input,
.design-details-input {
  width: 100%;
  padding: clamp(0.75rem, 2vw, 1rem);
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.8rem;
  color: #333;
  transition: all 0.2s ease;
}

/* Textarea Styles */
.project-details-textarea,
.integrations-textarea,
.design-details-textarea {
  min-height: 120px;
  width: 100%;
  padding: clamp(0.75rem, 2vw, 1rem);
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.8rem;
  color: #333;
  resize: vertical;
  transition: all 0.2s ease;
}

/* Phone Input Styles */
.phone-input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
}

.phone-input-wrapper:focus-within {
  border-color: #3D478C;
  box-shadow: 0 0 0 2px rgba(61, 71, 140, 0.1);
}

.phone-prefix {
  padding: 0.5rem 0.75rem;
  background: #f5f5f5;
  color: #666;
  font-size: 0.9rem;
  border-right: 1px solid #e0e0e0;
  user-select: none;
}

.phone-input-wrapper input {
  border: none !important;
  border-radius: 0 !important;
  flex: 1;
  padding-left: 0.75rem !important;
}

.phone-input-wrapper input:focus {
  box-shadow: none !important;
}

/* Form Group Styles */
.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.form-control:focus {
  outline: none;
  border-color: #3D478C;
  box-shadow: 0 0 0 2px rgba(61, 71, 140, 0.1);
}

.form-control.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  color: #dc3545;
  font-size: 0.8rem;
  margin-top: 0.25rem;
}

/* Checkbox Styles */
.personal-info-checkbox-container, .form-checkbox-container {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.personal-info-checkbox-label, .form-checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.personal-info-checkbox-input, .form-checkbox-input {
  width: 18px;
  height: 18px;
}

.personal-info-checkbox-text, .form-checkbox-text {
  font-size: clamp(0.875rem, 2vw, 1rem);
  color: #333;
}

/* Dropzone Styles */
.project-details-dropzone,
.design-details-dropzone {
  border: 2px dashed #3e478c;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background: rgba(62, 71, 140, 0.05);
  margin-bottom: 1rem;
}

/* Integration Plugin Styles */
.integrations-plugins-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.integrations-plugin-item {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.integrations-plugin-item:hover {
  border-color: #3e478c;
  background: rgba(62, 71, 140, 0.05);
}

.integrations-plugin-item.selected {
  border-color: #3e478c;
  background: rgba(62, 71, 140, 0.1);
}

.integrations-plugin-label {
  font-weight: 500;
  color: #3e478c;
  margin-left: 0.5rem;
}

.integrations-plugin-description {
  font-size: 0.75rem;
  color: #666;
  margin: 0.5rem 0;
  font-style: italic;
}

.integrations-plugin-price {
  font-size: 0.875rem;
  color: #3e478c;
  font-weight: 500;
}

/* Form Summary Styles */
.form-summary-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: #fff;
}

.summary-header {
  text-align: center;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #eee;
}

.logo {
  width: 150px;
  margin-bottom: 1rem;
}

.customer-id {
  color: #666;
  font-size: 1.1rem;
}

.summary-section {
  margin-bottom: 3rem;
  background: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.fields-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.field-item {
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.field-value {
  color: #333;
  word-break: break-word;
  margin-top: 0.5rem;
}

/* Form Navigation Styles */
.customer-form-navigation {
  position: sticky;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.customer-form-navigation button {
  min-width: 150px;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.customer-form-navigation button[type="submit"],
.customer-form-navigation button[type="button"].next {
  background: #3D478C;
  color: white;
  border: none;
}

.customer-form-navigation button[type="submit"]:hover,
.customer-form-navigation button[type="button"].next:hover {
  background: #2d3567;
}

.customer-form-navigation button[type="button"].prev {
  background: white;
  color: #3D478C;
  border: 2px solid #3D478C;
}

.customer-form-navigation button[type="button"].prev:hover {
  background: #f8f9fa;
}

/* Header Logout Button */
.header-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #dc3545;
  background: transparent;
  border: 1px solid #dc3545;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 6px;
}

.header-button:hover {
  background: #dc3545;
  color: white;
}

.header-button svg {
  width: 16px;
  height: 16px;
}

/* Delete/Remove Buttons */
.delete-button {
  padding: 0.5rem;
  border-radius: 4px;
  background: transparent;
  border: none;
  color: #dc3545;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-button:hover {
  background: rgba(220, 53, 69, 0.1);
}

@media (max-width: 768px) {
  .customer-form-navigation {
    flex-direction: column;
  }

  .customer-form-navigation button {
    width: 100%;
  }
}

/* Error State */
.highlight-missing {
  border-color: #ff4444 !important;
  background-color: #fff6f6 !important;
}

/* Toast Messages */
.Toastify__toast {
  font-family: 'Poppins', sans-serif;
  border-radius: 8px;
}

.Toastify__toast--error {
  background: #dc3545;
}

.Toastify__toast--success {
  background: #3ee8bb;
}

/* File Icon */
.file-icon {
  margin-right: 0.5rem;
  color: #3e478c;
  max-height: 12px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .customer-form-navigation {
    flex-direction: column;
  }

  .fields-grid {
    grid-template-columns: 1fr;
  }

  input, textarea {
    font-size: 0.8rem; /* Prevent iOS zoom */
  }

  .nav-button {
    width: 100%;
  }
}

/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.header-logo {
  height: 40px;
  width: auto;
}

.header-logo img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.header-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #3D478C;
  background: transparent;
  border: 1px solid #3D478C;
  cursor: pointer;
  transition: all 0.2s ease;
}

.header-button:hover {
  background: rgba(61, 71, 140, 0.1);
}

@media (max-width: 768px) {
  .header {
    padding: 1rem;
  }
  
  .header-logo {
    height: 32px;
  }
}

/* Container max-width for all sections */
.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 3rem;
  width: 100%;
}

/* Header Styles */
.header-wrapper {
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  z-index: 100;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
}

.header-logo {
  height: 40px;
  width: auto;
}

.header-logo img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.header-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #3D478C;
  background: transparent;
  border: 1px solid #3D478C;
  cursor: pointer;
  transition: all 0.2s ease;
}

.header-button:hover {
  background: rgba(61, 71, 140, 0.1);
}

/* Form Navigation Styles */
.navigation-wrapper {
  position: sticky;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  border-top: 1px solid #e0e0e0;
  z-index: 100;
}

.customer-form-navigation {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 0;
}

@media (max-width: 768px) {
  .container {
    padding: 0 1rem;
  }

  .header {
    height: 64px;
  }
  
  .header-logo {
    height: 32px;
  }
}

/* File Display Styles */
.file-list {
  list-style: none;
  padding: 0;
  margin: 0.75rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
}

.file-list div {
  display: flex;
  align-items: center;
}

.file-info {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease;
  max-width: 100%;
}

.file-info:hover {
  background: #e9e9e9;
  border-color: #3D478C;
  transform: translateY(-1px);
}

.file-name {
  font-size: 0.8rem;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-remove {
  margin-left: 0.5rem;
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.15rem;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.file-remove:hover {
  background: rgba(220, 53, 69, 0.1);
}

.dropzone {
  border: 2px dashed #3D478C;
  border-radius: 6px;
  padding: 1.25rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  background: rgba(61, 71, 140, 0.02);
}

.dropzone:hover,
.dropzone.drag-active {
  background: rgba(61, 71, 140, 0.05);
  border-color: #2d3567;
}

.dropzone-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.dropzone-text {
  color: #3D478C;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
}

.dropzone-info {
  color: #666;
  font-size: 0.75rem;
  line-height: 1.4;
  margin: 0;
}

/* Telefon input stilleri */
.react-tel-input {
  width: 100% !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 48px !important;
  padding: 0.75rem 0.75rem 0.75rem 50px !important;
  border: 2px solid #e0e0e0 !important;
  border-radius: 8px !important;
  font-size: 1rem !important;
  font-family: 'Poppins', sans-serif !important;
  color: #333 !important;
  background-color: #fff !important;
}

.react-tel-input .form-control:focus {
  border-color: #3e478c !important;
  box-shadow: 0 0 0 4px rgba(62, 71, 140, 0.1) !important;
  outline: none !important;
}

.react-tel-input .flag-dropdown {
  border: none !important;
  background: transparent !important;
  height: 48px !important;
}

.react-tel-input .selected-flag {
  width: 50px !important;
  height: 46px !important;
  padding: 0 0 0 12px !important;
  background: transparent !important;
  display: flex !important;
  align-items: center !important;
}

.react-tel-input .country-list {
  margin-top: 2px !important;
  border: 2px solid #e0e0e0 !important;
  border-radius: 8px !important;
  font-family: 'Poppins', sans-serif !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

/* Mobil için özel ayarlar */
@media (max-width: 768px) {
  .react-tel-input .form-control {
    height: 52px !important;
  }
  
  .react-tel-input .flag-dropdown {
    height: 52px !important;
  }
  
  .react-tel-input .selected-flag {
    height: 50px !important;
  }
}

/* Genel telefon input container stilleri */
.personal-info-phone,
.project-details-phone {
  width: 100%;
  margin-bottom: 1rem;
}

/* Özel class'lar için telefon input stilleri */
.personal-info-phone .react-tel-input,
.project-details-phone .react-tel-input {
  width: 100% !important;
}

.personal-info-phone-input,
.project-details-phone-input {
  width: 100% !important;
  height: 48px !important;
  padding: 0.75rem 0.75rem 0.75rem 50px !important;
  border: 2px solid #e0e0e0 !important;
  border-radius: 8px !important;
  font-size: 0.8rem !important;
  font-family: 'Poppins', sans-serif !important;
  color: #333 !important;
  background-color: #fff !important;
}

.personal-info-phone .react-tel-input .form-control,
.project-details-phone .react-tel-input .form-control {
  width: 100% !important;
  height: 48px !important;
  padding: 0.75rem 0.75rem 0.75rem 50px !important;
  border: 2px solid #e0e0e0 !important;
  border-radius: 8px !important;
  font-size: 1rem !important;
  font-family: 'Poppins', sans-serif !important;
  color: #333 !important;
  background-color: #fff !important;
}

.personal-info-phone .react-tel-input .form-control:focus,
.project-details-phone .react-tel-input .form-control:focus {
  border-color: #3e478c !important;
  box-shadow: 0 0 0 4px rgba(62, 71, 140, 0.1) !important;
  outline: none !important;
}

.personal-info-phone .react-tel-input .flag-dropdown,
.project-details-phone .react-tel-input .flag-dropdown {
  border: none !important;
  background: transparent !important;
  height: 48px !important;
}

.personal-info-phone .react-tel-input .selected-flag,
.project-details-phone .react-tel-input .selected-flag {
  width: 50px !important;
  height: 46px !important;
  padding: 0 0 0 12px !important;
  background: transparent !important;
  display: flex !important;
  align-items: center !important;
}

.personal-info-phone .react-tel-input .country-list,
.project-details-phone .react-tel-input .country-list {
  margin-top: 2px !important;
  border: 2px solid #e0e0e0 !important;
  border-radius: 8px !important;
  font-family: 'Poppins', sans-serif !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

/* Mobil için özel ayarlar */
@media (max-width: 768px) {
  .personal-info-phone .react-tel-input .form-control,
  .project-details-phone .react-tel-input .form-control,
  .personal-info-phone-input,
  .project-details-phone-input {
    height: 52px !important;
    font-size: 16px !important;
  }
  
  .personal-info-phone .react-tel-input .flag-dropdown,
  .project-details-phone .react-tel-input .flag-dropdown {
    height: 52px !important;
  }
  
  .personal-info-phone .react-tel-input .selected-flag,
  .project-details-phone .react-tel-input .selected-flag {
    height: 50px !important;
  }
}

/* Highlight durumu için stiller */
.personal-info-phone .react-tel-input .form-control.highlight,
.project-details-phone .react-tel-input .form-control.highlight {
  border-color: #ff4444 !important;
  background-color: #fff6f6 !important;
}

/* Eklenti checkbox container stilleri */
.plugins-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

/* Checkbox grup stilleri */
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

/* Checkbox container stilleri */
.checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: #f8f9fa;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.checkbox-container:hover {
  border-color: #3e478c;
  background-color: #f0f2ff;
}

/* Checkbox input stilleri */
.checkbox-container input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin: 0;
  cursor: pointer;
  accent-color: #3e478c;
}

/* Checkbox label stilleri */
.checkbox-container label {
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
  user-select: none;
  margin: 0;
}

/* Seçili checkbox container stilleri */
.checkbox-container.selected {
  background-color: #eef0ff;
  border-color: #3e478c;
}

/* Mobil için özel ayarlar */
@media (max-width: 768px) {
  .checkbox-container {
    width: 100%;
  }
  
  .checkbox-container label {
    font-size: 1rem;
  }
}

/* Entegrasyon checkbox container stilleri */
.integrations-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 0.5rem;
}

.integrations-checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: #f8f9fa;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.integrations-checkbox-container:hover {
  border-color: #3e478c;
  background-color: #f0f2ff;
}

/* Checkbox input stilleri */
.integrations-checkbox {
  width: 18px;
  height: 18px;
  margin: 0;
  cursor: pointer;
  accent-color: #3e478c;
}

/* Checkbox label stilleri */
.integrations-checkbox-label {
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
  user-select: none;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

/* Seçili checkbox container stilleri */
.integrations-checkbox-container.selected {
  background-color: #eef0ff;
  border-color: #3e478c;
}

/* Mobil için özel ayarlar */
@media (max-width: 768px) {
  .integrations-checkbox-container {
    width: 100%;
  }
  
  .integrations-checkbox-label {
    font-size: 1rem;
  }
}

/* Buton container */
.form-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding-top: 1.5rem;
  gap: 1rem;
  border-top: 1px solid #e0e0e0;
}

/* Geri butonu */
.backbutton {
  padding: 0.75rem 1.5rem;
  background-color: #fff;
  color: #3e478c;
  border: 2px solid #3e478c;
  border-radius: 8px;
  font-weight: 500;
  min-width: 120px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.backbutton:hover {
  background-color: #f0f2ff;
}

/* Kaydet butonu */
.savebutton {
  padding: 0.75rem 1.5rem;
  background-color: #4caf50;
  color: white;
  border: 2px solid #4caf50;
  border-radius: 8px;
  font-weight: 500;
  min-width: 120px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.savebutton:hover {
  background-color: #388e3c;
  border-color: #388e3c;
}

/* İleri butonu */
.nextbutton {
  padding: 0.75rem 1.5rem;
  background-color: #3e478c;
  color: white;
  border: 2px solid #3e478c;
  border-radius: 8px;
  font-weight: 500;
  min-width: 120px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.nextbutton:hover {
  background-color: #2d3567;
  border-color: #2d3567;
}

/* Formu Tamamla butonu */
.completebutton {
  padding: 0.75rem 1.5rem;
  background-color: #2196f3;
  color: white;
  border: 2px solid #2196f3;
  border-radius: 8px;
  font-weight: 500;
  min-width: 120px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.completebutton:hover {
  background-color: #1976d2;
  border-color: #1976d2;
}

/* Disabled durumu tüm butonlar için */
.backbutton:disabled,
.savebutton:disabled,
.nextbutton:disabled,
.completebutton:disabled {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Mobil için özel ayarlar */
@media (max-width: 768px) {
  .form-navigation {
    flex-direction: column;
    padding: 1rem 0;
  }

  .backbutton,
  .savebutton,
  .nextbutton,
  .completebutton {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
  }
}

.Toastify__toast--success {
  background-color: #ffffff;
  color: #4caf50;
  border: 1px solid #4caf50;
}

.file-list div {
  margin: 0.5rem;
}

/* Responsive tablo stilleri */
.admin-table-container {
  width: 100%;
  overflow-x: auto;
  margin: 1rem 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-table {
  width: 100%;
  min-width: 800px; /* Minimum tablo genişliği */
  border-collapse: collapse;
  background-color: white;
}

.admin-table th,
.admin-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  white-space: nowrap; /* Uzun içeriklerin satır atlamasını engelle */
}

.admin-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #333;
}

.admin-table tr:hover {
  background-color: #f5f5f5;
}

/* Mobil için özel düzenlemeler */
@media (max-width: 768px) {
  .admin-table-container {
    margin: 0.5rem 0;
    border-radius: 0;
  }

  .admin-table th,
  .admin-table td {
    padding: 0.75rem;
    font-size: 0.9rem;
  }

  /* Önemli sütunları vurgula */
  .admin-table td:first-child,
  .admin-table th:first-child {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 1;
    border-right: 1px solid #e0e0e0;
  }

  /* Hover durumunda sticky sütun arkaplanı */
  .admin-table tr:hover td:first-child {
    background-color: #f5f5f5;
  }
}

/* Durum badge'leri */
.status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.85rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

.status-completed {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status-in-progress {
  background-color: #fff3e0;
  color: #ef6c00;
}

.status-not-started {
  background-color: #f5f5f5;
  color: #757575;
}

/* Admin geri dönüş butonu */
.admin-back-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  margin-bottom: 2rem;
  background-color: #3e478c;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.admin-back-button:hover {
  background-color: #2d3567;
  transform: translateX(-4px);
}

.formrequiredsign{
  color: #ff4444;
}

/* Mobil için özel ayarlar */
@media (max-width: 768px) {
  .admin-back-button {
    width: 100%;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
}

.integrations-plugin-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.recommended-badge {
  display: inline-block;
  background-color: #2196f3;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-left: 8px;
  font-weight: 500;
}

.project-details-info.alert-info{
  padding: 2rem;
  background-color: #fff5c7;
  border-radius: 1rem;
  color: #000;
}