.admin-dashboard {
  padding: 30px 0;
  font-family: Arial, sans-serif;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f0f2f5;
  margin-bottom: 20px;
}

.logo {
  height: 40px;
}

.logout-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.logout-button:hover {
  background-color: #c82333;
}

.add-customer-form {
  margin-bottom: 20px;
}

.add-customer-form input {
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 80%;
}

.add-customer-form button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: calc(20% - 10px);
}

.add-customer-form button:hover {
  background-color: #0056b3;
}

.search-container {
  margin-bottom: 20px;
}

.search-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.customer-table {
  width: 100%;
  border-collapse: collapse;
}

.customer-table th,
.customer-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.customer-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.customer-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.customer-table tr:hover {
  background-color: #f5f5f5;
}

.customer-table a {
  color: #007bff;
  text-decoration: none;
}

.customer-table a:hover {
  text-decoration: underline;
}

.Toastify__toast {
  font-size: 14px;
  padding: 10px 15px;
  min-height: auto;
  border-radius: 4px;
}

.Toastify__toast-body {
  margin: 0;
  padding: 0;
}

.Toastify__close-button {
  align-self: center;
}


.view-form-link {
  color: #007bff;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.view-form-link:hover {
  background-color: #007bff;
  color: white;
  text-decoration: none;
}

/* Container max-width for all sections */
.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 3rem;
  width: 100%;
}

/* Header Styles */
.header-wrapper {
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  z-index: 100;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
}

.header-logo {
  height: 40px;
  width: auto;
}

.header-logo img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.header-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #dc3545;
  background: transparent;
  border: 1px solid #dc3545;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 6px;
}

.header-button:hover {
  background: #dc3545;
  color: white;
}

.header-button svg {
  width: 16px;
  height: 16px;
}

@media (max-width: 768px) {
  .container {
    padding: 0 1rem;
  }

  .header {
    height: 64px;
  }
  
  .header-logo {
    height: 32px;
  }

  .add-customer-form input {
    width: 70%;
    margin-right: 5px;
  }

  .add-customer-form button {
    width: calc(30% - 5px);
  }
}