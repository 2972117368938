.form-summary-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
}

.summary-header {
  text-align: center;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #eee;
}

.summary-header .logo {
  width: 150px;
  margin-bottom: 1rem;
}

.summary-header h1 {
  color: #333;
  margin: 1rem 0;
}

.customer-id {
  color: #666;
  font-size: 1.1rem;
}

.summary-section {
  margin-bottom: 3rem;
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.summary-section h2 {
  color: #3D478C;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #3D478C;
}

.fields-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.field-item {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
}

.field-item label {
  font-weight: 600;
  color: #3D478C;
  margin-bottom: 0.5rem;
}

.field-value {
  color: #333;
  word-break: break-word;
  margin-top: 0.5rem;
}

.file-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.file-link {
  display: flex;
  flex-direction: column;
  color: #3D478C;
  text-decoration: none;
  padding: 0.8rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
}

.file-link:hover {
  background-color: #e9e9e9;
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.file-info {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.file-icon {
  font-size: 1.5rem;
  color: #3D478C;
}


.loading, .error {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error {
  color: #dc3545;
}

@media (max-width: 768px) {
  .form-summary-container {
    padding: 1rem;
  }

  .fields-grid {
    grid-template-columns: 1fr;
  }

  .summary-section {
    padding: 1rem;
  }

  .thumbnail {
    max-width: 100%;
  }
} 

.file-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.file-item {
  flex: 0 0 auto;
}

.file-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.file-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.file-link {
  color: #0066cc;
  text-decoration: none;
  font-size: 0.9em;
  text-align: center;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-link:hover {
  text-decoration: underline;
}