.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #3e478c 0%, #3ee8bb 100%);
  font-family: 'Poppins', sans-serif;
  padding: 1rem;
}

.login-form-wrapper {
  background-color: white;
  padding: clamp(1.5rem, 5vw, 3rem);
  border-radius: clamp(8px, 2vw, 16px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: clamp(80px, 25vw, 120px);
  height: auto;
  margin-bottom: clamp(1rem, 4vw, 2rem);
}

.login-form {
  width: 100%;
}

.login-form h2 {
  color: #3e478c;
  font-size: clamp(1.25rem, 4vw, 1.75rem);
  font-weight: 600;
  text-align: center;
  margin-bottom: clamp(1rem, 4vw, 2rem);
}

.form-group {
  margin-bottom: clamp(1rem, 3vw, 1.5rem);
}

.form-group label {
  display: block;
  color: #3e478c;
  font-size: clamp(0.75rem, 2vw, 0.875rem);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-group input {
  width: 100%;
  padding: clamp(0.75rem, 2vw, 0.875rem);
  border: 2px solid #e0e0e0;
  border-radius: clamp(6px, 1.5vw, 8px);
  font-size: clamp(0.875rem, 2vw, 1rem);
  font-family: 'Poppins', sans-serif;
  transition: all 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: #3e478c;
  box-shadow: 0 0 0 4px rgba(62, 71, 140, 0.1);
}

.login-button {
  width: 100%;
  padding: clamp(0.75rem, 2vw, 1rem);
  background-color: #3e478c;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: clamp(0.875rem, 2vw, 1rem);
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.login-button:hover {
  background-color: #353d7a;
  transform: translateY(-1px);
}

.login-button:active {
  transform: translateY(0);
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  text-align: center;
  margin: 1rem 0;
}

.admin-link, .customer-link {
  margin-top: 1.5rem;
  text-align: center;
  color: #666;
  font-size: 0.875rem;
}

.admin-link a, .customer-link a {
  color: #3e478c;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.admin-link a:hover, .customer-link a:hover {
  color: #3ee8bb;
}

@media (max-width: 480px) {
  .login-form-wrapper {
    margin: 1rem;
    padding: 1.5rem;
  }
}
